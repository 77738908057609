
import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Badge, Button } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faSearch } from '@fortawesome/free-solid-svg-icons';





const Input = styled.input.attrs(props => ({
    type: "text",
    size: props.small ? 5 : undefined
}))`
    height: 32px;
    width: 250px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
  `;

const SearchButton = styled.button`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    position: relative;
    right: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;



const FilterSnComponent = ({ filterSnText, onFilter, onClick }) => {

    const inputRef = useRef(null);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 取得 input 元素的下一個 sibling 元素，並檢查是否為按鈕
            const button = inputRef.current.nextElementSibling;
            if (button && button.tagName === 'BUTTON') {
                button.click(); // 點擊按鈕
            }
        }
    };
    return (
        <>
            SN&nbsp;:&nbsp;<Input
                id="search"
                type="text"
                placeholder="Search S/N"
                value={filterSnText}
                onChange={onFilter}
                onKeyDown={handleKeyDown} // 新增 onKeyDown 事件處理
                ref={inputRef}
            />

            <SearchButton onClick={onClick} className="btn">
                <FontAwesomeIcon icon={faSearch} />
            </SearchButton>

        </>
    )
};

const FilterPoComponent = ({ filterPoText, onFilter, onClick }) => {

    const inputRef = useRef(null);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 取得 input 元素的下一個 sibling 元素，並檢查是否為按鈕
            const button = inputRef.current.nextElementSibling;
            if (button && button.tagName === 'BUTTON') {
                button.click(); // 點擊按鈕
            }
        }
    };
    return (
        <>
            PO#&nbsp;:&nbsp;<Input
                id="search"
                type="text"
                placeholder="Search failed by PO#"
                value={filterPoText}
                onChange={onFilter}
                onKeyDown={handleKeyDown} // 新增 onKeyDown 事件處理
                ref={inputRef}
            />

            <SearchButton onClick={onClick} className="btn">
                <FontAwesomeIcon icon={faSearch} />
            </SearchButton>

        </>
    )
};






const DatalogTable = props => {


    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [filterSnText, setFilterSnText] = React.useState("");
    const [filterPoText, setFilterPoText] = React.useState("");
    const [downloadPoText, setDownloadPoText] = React.useState("");
    const [isShowDownload, setIsShowDownload] = React.useState(false);
    const [sortBy, setSortBy] = useState('d1_id')
    const [direction, setDirection] = useState('desc')
    const [filteredTotalRows, setFilteredTotalRows] = useState(0); // 過濾後的數據總數

    const [dataHeaders] = useState([

        { label: 'S/N', key: 'd1_serial_number' },
        { label: 'MAC', key: '' },
        { label: 'Site', key: 'po_factory.name' },
        { label: 'Line#', key: 'd1_station.line.name' },
        { label: "Board Jig#", key: 'd1_station.name' },
        { label: "QC jig#", key: 'd2_station.name' },
        { label: "Status", key: 'progress_status' },
        { label: "WIP/PASS/FAIL", key: 'wip' },
        { label: "Radar Test", key: 'd1_items_result.radar' },
        { label: "MCU Test", key: 'd1_items_result.mcu_flash' },
        { label: "PS Test", key: 'd1_items_result.ps' },
        { label: "ESP Test", key: 'd1_items_result.esp_flash' },
        { label: "S/N Board Test", key: 'd1_items_result.sn' },
        { label: "S/N QC  Test", key: 'd2_items_result.sn' },
        { label: "Wifi QC  Test", key: 'd2_items_result.wifi' },
        { label: "Reflow (Y/N)", key: 'reflow' },
        { label: "CFG Test", key: 'd1_cfg' },
        { label: "Box#", key: 'box_number' },
        { label: "Carton#", key: 'carton_number' },
        { label: "Pallet#", key: 'pallet_number' },
        { label: "Board Test Time", key: 'tester_time' },
        { label: "QC Test Time", key: 'qc_time' },
    ]);
    const noDataRow = {
        d1_serial_number: "",
        d1_station: {
            factory: { name: "" },
            line: { name: "" },
            name: ""
        },
        po: {
            factory: {
                name: ""
            }
        },
        d2_station: { name: "" },
        progress_status: "",
        d1_result: "",
        d1_items_result: {
            radar: "",
            mcu_flash: "",
            ps: "",
            esp_flash: "",
            sn: "",
            wifi: ""
        },
        d2_items_result: {
            sn: "",
            wifi: ""
        },
        reflow: "",
        d1_cfg: "",
        box: "",
        carton: "",
        pallet: "",
        tester_time: "",
        qc_time: "",
        wip: null
    };

    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`, // 替換為你的 token
        'Content-Type': 'application/json',
    };
    const [filters, setFilters] = useState({});


    const Status_view = {
        "PASS": <Badge bg="success">PASS</Badge>,
        "FAIL": <Badge bg="danger">FAIL</Badge>,
        "0": ""
    }

    const Status = {
        "1": "PASS",
        "2": "FAIL",
        "0": ""
    }



    const progressStatus_view = {
        'Board Test': <Badge bg="primary">Board Test</Badge>,
        'Assembled': <Badge bg="primary">Assembled</Badge>,
        'QC test': <Badge bg="primary">QC test</Badge>,
        'Packed': <Badge bg="primary">Packed</Badge>,
        'Box Ready': <Badge bg="primary">Box Ready</Badge>,
        'Cartons Ready': <Badge bg="primary">Cartons Ready</Badge>,
        'Shipped': <Badge bg="primary">Shipped</Badge>,
        'In Quarantine': <Badge bg="primary">In Quarantine</Badge>,
    }
    //In QC Jig, In Tester Jig, In Packaging, Packed, Shipped
    const progressStatus = {
        0: 'start',
        1: 'Board Test',
        2: 'Assembled',
        3: 'QC test',
        4: 'Packed',
        5: 'Box Ready',
        6: 'Cartons Ready',
        7: 'Shipped',
    }



    const handleFilterChange = async (value, column) => {
        console.log(value)
        console.log(column)
        await setFilters(prevFilters => {
            const newFilters = { ...prevFilters }; // 复制当前的 filters

            if (value === "") {
                // 如果 value 是空字符串，删除该字段
                delete newFilters[column];
            } else {
                // 否则更新该字段的值
                newFilters[column] = value;
            }

            return newFilters; // 返回更新后的 filters 对象
        });
        await setCurrentPage(1); // Reset to first page when filters change
        // fetchPoData(1, perPage)
        console.log(filters)
    };


    // 提取所有唯一的名字、年齡和職位
    const uniqueD1_serial_number = [...new Set(data.map((item) => item.d1_serial_number || ""))];
    const uniqueSite = [...new Set(data.map((item) => (item.po_factory && item.po_factory.name) || ""))];
    const uniqueLineName = [...new Set(data.map((item) => (item.d1_station && item.d1_station.line && item.d1_station.line.name) || ""))];
    const uniqueBoardJig = [...new Set(data.map((item) => (item.d1_station && item.d1_station.name) || ""))];
    const uniqueQcJig = [...new Set(data.map((item) => (item.d2_station && item.d2_station.name && item.d2_station.name) || ""))];
    const uniqueStatus = [...new Set(data.map((item) => item.progress_status || ""))];
    const uniqueWIP = [...new Set(data.map((item) => item.wip || ""))];
    const uniqueRadar = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.radar) || ""))];
    const uniqueMCU = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.mcu_flash) || ""))];
    const uniquePS = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.ps) || ""))];
    const uniqueConfig = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.config) || ""))];
    const uniqueESP = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.esp_flash) || ""))];
    const uniqueBoardSN = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.sn) || ""))];
    const uniqueBoardWiFi = [...new Set(data.map((item) => (item.d1_items_result && item.d1_items_result.wifi) || ""))];
    const uniqueQcSN = [...new Set(data.map((item) => (item.d2_items_result && item.d2_items_result.sn) || ""))];
    const uniqueQcWifi = ["PASS", "FAIL"];
    const uniqueReflow = [...new Set(data.map((item) => item.reflow || ""))];
    const uniqueCfg = [...new Set(data.map((item) => item.d1_cfg || ""))];
    const uniqueBox = [...new Set(data.map((item) => item.box_number || ""))];
    const uniqueCarton = [...new Set(data.map((item) => item.carton_number || ""))];
    const uniquePallet = [...new Set(data.map((item) => item.pallet_number || ""))];
    const uniquePoNumber = [...new Set(data.map((item) => item.po_number || ""))];


    const selectStyles = {
        backgroundColor: 'transparent',
        color: 'white',
        border: '0px',
    };
    // 使用 CSS 控制 option 展開後的文字顏色
    const optionStyles = {
        color: 'black', // 黑色文字
    };

    const columns = (filters, handleFilterChange) => [
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'd1_serial_number')} style={selectStyles}>
                        <option value="" style={optionStyles}>S/N</option>
                        {uniqueD1_serial_number.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            width: 'auto', // 使用 'auto' 让列宽度根据内容自适应
            selector: row => row.d1_serial_number,
            width: "150px"
        },
        {
            name: (
                <div>
                    MAC
                </div>
            ),
            selector: row => row.mac,
            width: "150px",
            center: "true",

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'po_number')} style={selectStyles}>
                        <option value="" style={optionStyles}>PO#</option>
                        {uniquePoNumber.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.po_number,
            width: 'auto', // 使用 'auto' 让列宽度根据内容自适应

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'po_factory.name')} style={selectStyles}>
                        <option value="" style={optionStyles}>Sites</option>
                        {uniqueSite.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.po_factory ? row.po_factory.name : "",

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'line')} style={selectStyles}>
                        <option value="" style={optionStyles}>Line#</option>
                        {uniqueLineName.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => `${row.d1_station.line.name}`,

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'board')} style={selectStyles}>
                        <option value="" style={optionStyles}>Board Jig#</option>
                        {uniqueBoardJig.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => `${row.d1_station.name}`,
        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'qc')} style={selectStyles}>
                        <option value="" style={optionStyles}>QC Jig#</option>
                        {uniqueQcJig.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.d2_station !== null ? `${row.d2_station.name}` : "",

        },
        {
            name: (
                <div>
                    <select
                        onClick={(e) => e.stopPropagation()} // 阻止 sortable 觸發
                        onChange={(e) => handleFilterChange(e.target.value, 'status')}
                        style={selectStyles}
                    >
                        <option value="" style={optionStyles}>Status</option>
                        {uniqueStatus.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => progressStatus_view[row.progress_status],
            sortable: true,
            width: "130px"
        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'wip')} style={selectStyles}>
                        <option value="" style={optionStyles}>WIP</option>
                        {uniqueWIP.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row =>
                row.wip !== null ?
                    row.wip == 'wip' ? <Badge bg="secondary">WIP</Badge> :
                        row.wip == "PASS" ? Status_view['PASS'] : Status_view['FAIL']
                    : ""

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'radar')} style={selectStyles}>
                        <option value="" style={optionStyles}>Radar Test</option>
                        {uniqueRadar.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.radar],
            width: "110px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'mcu')} style={selectStyles}>
                        <option value="" style={optionStyles}>MCU Test</option>
                        {uniqueMCU.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.mcu_flash],
            width: "115px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'ps')} style={selectStyles}>
                        <option value="" style={optionStyles}>PS Test</option>
                        {uniquePS.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.ps],

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'config')} style={selectStyles}>
                        <option value="" style={optionStyles}>Config</option>
                        {uniqueConfig.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.config],

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'esp')} style={selectStyles}>
                        <option value="" style={optionStyles}>ESP Test</option>
                        {uniqueESP.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.esp_flash],

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'boardSN')} style={selectStyles}>
                        <option value="" style={optionStyles}>S/N Board Test</option>
                        {uniqueBoardSN.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.sn],
            width: "135px"
        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'boardWifi')} style={selectStyles}>
                        <option value="" style={optionStyles}>WiFi Board Test</option>
                        {uniqueBoardWiFi.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d1_items_result.wifi],
            width: "135px"
        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'qcSN')} style={selectStyles}>
                        <option value="" style={optionStyles}>S/N QC Test</option>
                        {uniqueQcSN.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d2_items_result.sn],
            center: "true",
            width: "135px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'qcWifi')} style={selectStyles}>
                        <option value="" style={optionStyles}>WiFi QC Test</option>
                        {uniqueQcWifi.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => Status_view[row.d2_items_result.wifi],
            center: "true",
            width: "135px"
        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'reflow')} style={selectStyles}>
                        <option value="" style={optionStyles}>Reflow (Y/N)</option>
                        {uniqueReflow.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.reflow,
            center: "true",
            width: "130px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'd1_cfg')} style={selectStyles}>
                        <option value="" style={optionStyles}>CRC Check</option>
                        {uniqueCfg.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.d1_cfg,
            center: "true",
            width: "120px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'box_number')} style={selectStyles}>
                        <option value="" style={optionStyles}>Box#</option>
                        {uniqueBox.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.box_number,
            center: "true",
            width: "130px"
        },

        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'carton_number')} style={selectStyles}>
                        <option value="" style={optionStyles}>Carton#</option>
                        {uniqueCarton.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.carton_number,
            center: "true",
            width: "130px"

        },
        {
            name: (
                <div>
                    <select onChange={(e) => handleFilterChange(e.target.value, 'pallet_number')} style={selectStyles}>
                        <option value="" style={optionStyles}>Pallet#</option>
                        {uniquePallet.map((name) => (
                            <option key={name} value={name} style={optionStyles}>{name}</option>
                        ))}
                    </select>
                </div>
            ),
            selector: row => row.pallet_number,
            center: "true",
            width: "130px"

        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: '100%' }}>Board Test Time</strong>,
            selector: row => row.tester_time,
            width: "200px"

        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: '100%' }}>QC Test Time</strong>,
            selector: row => row.qc_time,
            width: "200px"

        },



    ];


    function formatDate(dateString) {
        // 解析 ISO 8601 格式的日期时间字符串
        const date = new Date(dateString);

        // 获取日期部分的各个组成部分
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从 0 开始，所以要加 1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // 格式化为 "YYYY-mm-dd HH:ii:ss" 格式
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }


    const formatData = async (rows) => {
        await rows.map((row) => {
            row.tester_time = row.d1_timestamp == null ? "" : formatDate(row.d1_timestamp);
            if (row.mac == null && row.d1_details_test_log != null) {
                let log;
                try {
                    log = JSON.parse(row.d1_details_test_log);
                    log.forEach(element => {
                        if (element.spec_code == 'TC430') {
                            row.mac = element.ref;
                        }
                    });
                } catch (error) {
                    // 如果解析失败，返回原始字符串或其他默认值
                    row.mac = '--:--:--:--:--:--';
                }
            } else {
                row.mac = row.mac == null ? '--:--:--:--:--:--' : row.mac;
            }

            row.qc_time = row.d2_timestamp == null ? "" : formatDate(row.d2_timestamp);
            if (row.d1_result == "2" || row.d2_result == "2") {
                row.progress_status = "In Quarantine";
            } else {
                row.progress_status = row.progress_status != null ? progressStatus[row.progress_status] : "";
            }
            row.d1_result = Status[row.d1_result] != null ? row.d1_result : "";
            row.d2_result = Status[row.d2_result] != null ? row.d2_result : "";
            row.d1_items_result.sn = row.d1_items_result.sn != null && row.d1_items_result.sn != 0 ? row.d1_items_result.sn == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.radar = row.d1_items_result.radar != null && row.d1_items_result.radar != 0 ? row.d1_items_result.radar == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.config = row.d1_items_result.config != null && row.d1_items_result.config != 0 ? row.d1_items_result.config == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.mcu_flash = row.d1_items_result.mcu_flash != null && row.d1_items_result.mcu_flash != 0 ? row.d1_items_result.mcu_flash == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.ps = row.d1_items_result.ps != null && row.d1_items_result.ps != 0 ? row.d1_items_result.ps == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.esp_flash = row.d1_items_result.esp_flash != null && row.d1_items_result.esp_flash != 0 ? row.d1_items_result.esp_flash == "1" ? Status[1] : Status[2] : "";
            row.d1_items_result.wifi = row.d1_items_result.wifi != null && row.d1_items_result.wifi != 0 ? row.d1_items_result.wifi == "1" ? Status[1] : Status[2] : "";
            if (row.d2_items_result != null) {
                row.d2_station.name = row.d2_station.name !== null ? `${row.d2_station.name}` : "";
                row.d2_items_result.sn = row.d2_items_result != null ? row.d2_items_result.sn == "1" ? Status[1] : Status[2] : "";
                row.d2_items_result.wifi = row.d2_items_result != null ? row.d2_items_result.wifi == "1" ? Status[1] : Status[2] : "";
            } else {
                row.d2_items_result = { sn: "", wifi: "" };
                row.d2_station = { name: "" };
            }
            row.reflow = row.d1_reflow != null ? row.d2_reflow || row.d1_reflow ? "Y" : "N" : "N";
            row.d1_cfg = row.d1_cfg != null ? row.d1_cfg ? Status_view['PASS'] : Status_view['FAIL'] : "";
            row.box_number = row.box_number != null ? row.box_number : "";
            row.carton_number = row.carton_number != null ? row.carton_number : "";
            row.pallet_number = row.pallet_number != null ? row.pallet_number : "";
            row.wip =
                row.d1_result != "" && row.d2_result != ""
                    ? row.d1_result == "1" && row.d2_result == "1" ? "PASS" : "FAIL"
                    : row.d1_result == "2" ? "FAIL" : 'wip'

            return null;
        })
        console.log(rows);
        setData(rows);
        setLoading(false)

        setFilters([]);
    }
    const fetchSerialNumberData = async (page, size) => {
        if (filterSnText === "") {
            return;
        }
        setLoading(true)
        page -= 1;
        try {
            const response = await axios.get(`${window.api_url}/tests/device/sn/${filterSnText}`, {
                headers,
                params: {
                    page,
                    size
                }
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    setLoading(false)
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else if (error.response && error.response.status === 404) {
                    formatData([])
                } else {
                    formatData([])
                    console.error("獲取數據時出錯了！", error);
                }
            });

            //console.log(response);

            if (response.data.data != null) {
                var rows = response.data.data.page.content;
                formatData(rows);
                setFilteredTotalRows(response.data.data.page.total_elements); // 根据实际 API 响应结构调整
                setTotalRows(response.data.data.total_count_before_filter);
                if (rows.length > 0) {
                    setDownloadPoText(rows[0].po_number)
                    setIsShowDownload(true);
                }
            }
            setLoading(false)


        } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
        }
    };

    const fetchPoData = async (page, size,) => {

        if (filterPoText === "") {
            return;
        }
        setDownloadPoText(filterPoText)
        setLoading(true)
        page -= 1;
        let filtersUrl = encodeURIComponent(filters);
        try {
            const response = await axios.get(`${window.api_url}/tests/device/po/${encodeURIComponent(filterPoText)}`, {
                headers,
                params: {
                    page,
                    size,
                    sortBy,
                    direction,
                    filtersUrl
                }
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                    formatData([]);
                    setLoading(false)
                }
            });

            //console.log(response);
            setIsShowDownload(false);
            if (response.data.data != null) {
                var rows = [...response.data.data.page.content]; // 确保创建了一个新数组
                formatData(rows);
                setFilteredTotalRows(response.data.data.page.total_elements); // 根据实际 API 响应结构调整
                setTotalRows(response.data.data.total_count_before_filter);
                setIsShowDownload(true);
            }
            setLoading(false)


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {

        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            //fetchData(currentPage, perPage);
        }

    }, [currentPage, perPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchPoData(page, perPage)
    };

    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page); // Reset to first page if perPage changes
        fetchPoData(page, newPerPage)
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(`${window.api_url}/tests/time/csv/${downloadPoText}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/csv',
                    'Authorization': `Bearer ${token}`, // 替換為你的 token
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = downloadPoText + '.csv'; // 默认下载的文件名
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        //console.log('Selected Rows: ', selectedRows);
    };

    const handleSort = (column, sortDirection) => {
        console.log(column.selector, sortDirection);
        setSortBy('progress_status');
        setDirection(sortDirection);
        fetchPoData(currentPage, perPage)

    };

    const [search, setSearch] = useState({ d1_serial_number: "" });

    const filteredData = data.filter((item) => {

        //console.log(item.wip, filters.d1_result)
        //console.log(item.wip === filters.d1_result)
        // console.log(filters, item.progress_status)
        return (
            (filters.site ? item.po_factory && item.po_factory.name === filters.site : true) &&
            (filters.d1_serial_number ? item.d1_serial_number === filters.d1_serial_number : true) &&
            (filters.line ? item.d1_station.line.name === filters.line : true) &&
            (filters.board ? item.d1_station.name === filters.board : true) &&
            (filters.qc ? item.d2_station.name === filters.qc : true) &&
            (filters.status ? item.progress_status === filters.status : true) &&
            (filters.wip ? item.wip === filters.wip : true) &&
            (filters.radar ? item.d1_items_result.radar === filters.radar : true) &&
            (filters.ps ? item.d1_items_result.ps === filters.ps : true) &&
            (filters.mcu ? item.d1_items_result.mcu_flash === filters.mcu : true) &&
            (filters.config ? item.d1_items_result.config === filters.config : true) &&
            (filters.esp ? item.d1_items_result.esp_flash === filters.esp : true) &&
            (filters.boardSN ? item.d1_items_result.sn === filters.boardSN : true) &&
            (filters.qcWifi ? item.d2_items_result.wifi === filters.qcWifi : true) &&
            (filters.qcSN ? item.d2_items_result.sn === filters.qcSN : true) &&
            (filters.reflow ? item.reflow === filters.reflow : true) &&
            (filters.d1_cfg ? item.d1_cfg === filters.d1_cfg : true) &&
            (filters.box_number ? item.box_number === filters.box_number : true) &&
            (filters.carton_number ? item.carton_number === filters.carton_number : true) &&
            (filters.pallet_number ? item.pallet_number === filters.pallet_number : true)

        );
    });


    // { label: "Radar Test", key: 'd1_items_result.radar' },
    // { label: "MCU Test", key: 'd1_items_result.mcu_flash' },
    // { label: "PS Test", key: 'd1_items_result.ps' },
    // { label: "ESP Test", key: 'd1_items_result.esp_flash' },
    // { label: "S/N Board Test", key: 'd1_items_result.sn' },
    // { label: "S/N QC  Test", key: 'd2_items_result.sn' },
    // { label: "Wifi QC  Test", key: 'd2_items_result.wifi' },
    // { label: "Reflow (Y/N)", key: 'reflow' },
    // { label: "CFG Test", key: 'cfg' },
    // { label: "Box#", key: 'box' },
    // { label: "Carton#", key: 'carton' },
    // { label: "Pallet#", key: 'pallet' },
    // { label: "Board Test Time", key: 'tester_time' },
    // { label: "QC Test Time", key: 'qc_time' },

    const subHeaderComponent = useMemo(() => {
        const handleSearchSn = () => {
            fetchSerialNumberData(1, perPage);
            setFilterPoText("");
            setIsShowDownload(false);
        };
        const handleSearchPo = () => {
            fetchPoData(1, perPage)
            setFilterSnText("");
        };

        return (

            <>
                <FilterSnComponent
                    onFilter={e => { setFilterSnText(e.target.value); }}
                    filterSnText={filterSnText}
                    onClick={handleSearchSn}
                />
                &nbsp;
                <FilterPoComponent
                    onFilter={e => { setFilterPoText(e.target.value); }}
                    filterPoText={filterPoText}
                    onClick={handleSearchPo}
                />

                {isShowDownload && (
                    <Button type="button" onClick={handleDownload}>
                        <FontAwesomeIcon icon={faFileCsv} />
                    </Button>
                )}
            </>
        );
    }, [filterSnText, filterPoText, data]);

    const ExpandedComponent = ({ data }) => {
        // 辅助函数用于安全地解析 JSON 字符串
        const parseJsonSafe = (jsonString) => {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                // 如果解析失败，返回原始字符串或其他默认值
                return null;
            }
        };

        let d1Log;
        if (data.d1_details_test_log != null) {
            d1Log = parseJsonSafe(data.d1_details_test_log);
        }
        let d2Log;
        if (data.d1_details_test_log != null) {
            d2Log = parseJsonSafe(data.d2_details_test_log);
        }

        return (
            <pre>
                {d1Log ? JSON.stringify(d1Log, null, 2) : 'Invalid or undefined JSON in Borad Test log'}
                <br />
                {d2Log ? JSON.stringify(d2Log, null, 2) : 'Invalid or undefined JSON in QC Test log'}
            </pre>
        );
    };
    const rowPreDisabled = row => row.d1_serial_number == "";

    const customFooter = () => {
        return null; // 這樣不會顯示 DataTable 的預設頁腳內容
    };
    return (
        <div>

            <DataTable
                pagination
                expandableRows
                paginationServer
                paginationPerPage={perPage}
                expandableRowsComponent={ExpandedComponent}
                expandableRowDisabled={rowPreDisabled}
                paginationRowsPerPageOptions={[10, 20, 30]}
                highlightOnHover
                progressPending={loading}
                columns={columns(filters, handleFilterChange)}
                data={filteredData.length > 0 ? filteredData : [noDataRow]} // 使用假數據行
                paginationTotalRows={filteredTotalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerPageChange}
                onSelectedRowsChange={handleChange}
                onSort={handleSort} // 設置排序事件回調
                subHeader
                subHeaderComponent={subHeaderComponent}
                customFooter={customFooter} // 自定義頁腳，這裡返回 null 以隱藏預設訊息

            />

            <div style={{ padding: '2px 5px', textAlign: 'right', backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.54)" }}>
                <p>
                    filtered from {totalRows} total entries
                </p>
            </div>
        </div>
    );
}

export default DatalogTable;